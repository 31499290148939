@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    width:100%;
    height:auto;
    aspect-ratio : 2/1;
    background-repeat : "no-repeat";
    background-color : black;
    /* background-image: url("./images/bg.png"); */
    background-size: "cover";
    background-position:"center";
}